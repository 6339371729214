import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const Cite = require('citation-js')


function get_bib_node(queryResult, name) {
	const q = queryResult.allBib.edges
	let bibNode = false
	for(let i = 0; i < q.length; i++) {
		if(q[i].node.name === name) {
			bibNode = q[i].node
			break
		}
	}
	return bibNode
}

const Bibliography = ({ name, useFormatted = true, boldFace = ['Prasertsom, P.', 'Ponrawee Prasertsom'], convertURLToLink = true, nosort = false, className = null, rawBib = false, template = 'apa', sortByYear = false, format = 'html' }) => {
	const q = useStaticQuery(graphql`
	query allBibQuery {
		allBib {
			edges {
				node {
					id
					content
					formatted
					name
				}
			}
		}
	}	
	`)

	let content, bibNode

	if(rawBib) {
		content = rawBib
	} else {
		bibNode = get_bib_node(q, name)
		content = bibNode.content
	} 

	let formatted
	
	if(!rawBib && useFormatted) { 
		formatted = bibNode.formatted
	}
	else {
		let cite = new Cite(content)
		if(!nosort && sortByYear) {
			cite = cite.sort((e1, e2) => parseInt(e2['issued']['date-parts'][0][0], 10) - parseInt(e1['issued']['date-parts'][0][0], 10))
		}
		formatted = cite.format('bibliography', {
			format: format,
			template: template,
			nosort: nosort
		})
	}

	// convert urls
	if(convertURLToLink) {
		formatted = formatted.replace(/(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim, '<a class="external-link" target="_blank" href="$1">$1</a>')
	}

	// boldface my name
	if(boldFace) {
		for(let name of boldFace) {
			formatted = formatted.replace(new RegExp(`(${name})`, 'g'), '<strong>$1</strong>')
		}
	}

	return (
		<div className={['bibliography', className].join(' ').trim()} dangerouslySetInnerHTML={{__html: formatted}}></div>
	)
}

export default Bibliography
